import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/baby/eczema/eczema-bodywash-product-front.webp";
import backImage from "images/baby/eczema/eczema-bodywash-product-back.webp";
import frontZoomImage from "images/baby/eczema/eczema-bodywash-product-front-zoom.webp";
import backZoomImage from "images/baby/eczema/eczema-bodywash-product-back-zoom.webp";

import img4 from "images/callouts/cout-baby-ointment-footer.webp";
import img5 from "images/callouts/cout-baby-eczema-footer.webp";

import imgChart1 from "images/baby/eczema/chart-eucerin-eczema-skin-tolerability.webp";
import imgChart2 from "images/baby/eczema/chart-eucerin-eczema-itching.webp";
import imgChart3 from "images/baby/eczema/chart-eucerin-eczema-dry-irritation.webp";

import "./eucerinbabyeczemareliefbodywash.scss";

function EucerinBabyEczemaReliefBodyWashPage({ pageContext }) {
  useGoToAnchor();

  return (
    <Layout
      pageClass="page-baby-eczema-relief-body-wash page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Baby Eczema Cream & Body Wash"
        pageDescription="Learn about Eucerin Baby Eczema Relief Cream & Body Wash"
      />
      <div className="gradient-bg gray-grad">
        <div className="inner-body-content gradient-bg">
          <div className="top-content-level-3">
            <section className="inner-centered-container">
              <ProductDetails
                categoryBgColor="#A70531"
                categoryName="ECZEMA"
                categoryFontColor="#ffffff"
                productName="Eucerin<sup>®</sup> Baby Eczema Relief Cream & Body Wash"
                productSlider={[frontImage, backImage]}
                zoomImages={[frontZoomImage, backZoomImage]}
              >
                <div className="functions">
                  <p className="font-semi-bold red-text sub-title">
                    A gentle, non-foaming wash to help calm babies' itch from
                    eczema
                  </p>
                  <p>
                    <span className="font-semi-bold">
                      Eucerin Baby Eczema Relief Cream &amp; Body Wash
                    </span>{" "}
                    is specifically designed for the unique needs of babies’
                    eczema-prone skin. It’s part of a complete routine to help
                    relieve the symptoms of eczema in pediatric patients as
                    young as 3 months.
                  </p>
                  <ul className="red-bullets">
                    <li>
                      <span>
                        Gentle on sensitive skin—<nobr>non-drying</nobr> and
                        non-irritating
                      </span>
                    </li>
                    <li>
                      <span className="font-semi-bold">
                        Clinically proven to help lessen the irritation and
                        itching that can be caused by bathing
                        <sup>1</sup>
                      </span>
                    </li>
                    <li>
                      <span>
                        Low pH formula helps protect and soothe as it washes
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="key-ingredients">
                  <p className="font-semi-bold red-text sub-title">
                    Active ingredient
                  </p>
                  <p className="font-semi-bold">
                    2% colloidal oatmeal (skin protectant)
                  </p>
                  <ul className="red-bullets">
                    <li>
                      <span>
                        Provides a protective layer to soothe; reduces itch
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="key-ingredients no-brd">
                  <p className="font-semi-bold red-text sub-title">
                    Key ingredients
                  </p>
                  <p className="font-semi-bold">Ceramide NP</p>
                  <ul className="red-bullets">
                    <li>
                      <span>Helps support skin’s moisture barrier</span>
                    </li>
                  </ul>

                  <p className="font-semi-bold">Gentle surfactant system</p>
                  <ul className="red-bullets">
                    <li>
                      <span>
                        Gentle, non-foaming, low&#8209;surfactant formula
                        minimizes dryness and irritation from bathing
                      </span>
                    </li>
                  </ul>
                </div>
              </ProductDetails>
              <Row>
                <Col xs={12}>
                  <div className="features">
                    <p className="font-semi-bold red-text sub-title">
                      Formulated for eczema-prone skin
                    </p>
                    <ul className="checkmarks red">
                      <li>
                        <span>Fragrance-free </span>
                      </li>
                      <li>
                        <span>dye-free </span>
                      </li>
                      <li>
                        <span>paraben-free</span>
                      </li>
                      <li>
                        <span>non-comedogenic</span>
                      </li>
                    </ul>
                  </div>
                  <p className="footnotes">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
              </Row>
            </section>
          </div>
          <div className="gradient-bg">
            <section className="inner-centered-container">
              <AccordionContent
                accordionHeading="SKIN TOLERABILITY"
                productThemeColor="eczema-red"
                id="skintolerability"
              >
                <Row align="center" justify="center" direction="row">
                  <Col sm={12} md={8}>
                    <img
                      src={imgChart1}
                      className="accordion-chart"
                      alt="Graph with improvements in skin tolerability by Week 1"
                    />
                    <p className="footnotes">
                      <span className="font-bold">Study design:</span> Duration
                      of 2 weeks, with tolerability evaluations and
                      self-assessment questionnaires conducted at baseline, Week
                      1, and Week 2 clinical visits. Patients, 2 months to 18
                      years (N=35), bathed with wash once daily.
                    </p>
                    <p className="references-symbols double last">
                      **Statistically significant improvement from baseline (
                      <span className="font-italic">p</span>
                      &lt;0.001).
                    </p>
                    <p className="references">
                      <span className="font-bold">Reference: 1.</span> Data on
                      file. Beiersdorf Inc.
                    </p>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="bordered-content">
                      <p className="spacer">46% improvement in erythema</p>
                      <p className="spacer">44% improvement in dryness</p>
                      <p className="spacer">55% improvement in itching</p>
                    </div>
                  </Col>
                </Row>
              </AccordionContent>

              <AccordionContent
                accordionHeading="ITCHING"
                productThemeColor="eczema-red"
                id="itching"
              >
                <Row align="center" justify="center" direction="row">
                  <Col sm={12} md={8}>
                    <img
                      src={imgChart2}
                      className="accordion-chart"
                      alt="Graph with number of patients reporting decrease in itching after bathing"
                    />
                    <p className="footnotes">
                      <span className="font-bold">Study design:</span> Duration
                      of 2 weeks, with tolerability evaluations and
                      self-assessment questionnaires conducted at baseline, Week
                      1, and Week 2 clinical visits. Patients, 2 months to 18
                      years (N=35), bathed with wash once daily.
                    </p>
                    <p className="references-symbols last">
                      *Statistically significant improvement from baseline (
                      <span className="font-italic">p</span>&le;0.05).
                    </p>
                    <p className="references">
                      <span className="font-bold">Reference: 1.</span> Data on
                      file. Beiersdorf Inc.
                    </p>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="bordered-content">
                      Statistically significant reduction in patients reporting
                      itching after bathing
                    </div>
                  </Col>
                </Row>
              </AccordionContent>

              <AccordionContent
                accordionHeading="DRYNESS & IRRITATION"
                productThemeColor="eczema-red"
                id="drynessirritation"
              >
                <Row align="center" justify="center" direction="row">
                  <Col sm={12} md={8}>
                    <img
                      src={imgChart3}
                      className="accordion-chart"
                      alt="Graph with number of patients reporting decrease in dryness and irritation after bathing"
                    />
                    <p className="footnotes">
                      <span className="font-bold">Study design:</span> Duration
                      of 2 weeks, with tolerability evaluations and
                      self-assessment questionnaires conducted at baseline, Week
                      1, and Week 2 clinical visits. Patients, 2 months to 18
                      years (N=35), bathed with wash once daily.
                    </p>
                    <p className="references-symbols double last">
                      **Statistically significant improvement from baseline (
                      <span className="font-italic">p</span>
                      &le;0.001).
                    </p>
                    <p className="references">
                      <span className="font-bold">Reference: 1.</span> Data on
                      file. Beiersdorf Inc.
                    </p>
                  </Col>
                  <Col sm={12} md={4}>
                    <div className="bordered-content">
                      <p className="spacer">
                        2x less dryness and irritation after bathing at Week 1
                      </p>
                      <p className="spacer">
                        4x less dryness and irritation after bathing at Week 2
                      </p>
                    </div>
                  </Col>
                </Row>
              </AccordionContent>
            </section>
          </div>

          <section className="callouts-container gradient-bg blue-grad">
            <div className="inner-centered-container">
              <Row>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img4}
                    imgAlt="AQUAPHOR® BABY HEALING OINTMENT"
                    caption="Help protect babies' skin from <br class='show-desktop' /> drool rash to diaper rash"
                    buttonText="AQUAPHOR<sup>®</sup> BABY HEALING OINTMENT"
                    buttonUrl="/baby/generalcare/aquaphorbabyhealingointment"
                    imgStyles={{ height: 158 }}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img5}
                    imgAlt="EUCERIN® BABY ECZEMA RELIEF"
                    caption="Recommend a proactive regimen <br class='show-desktop' /> for babies' eczema-prone skin"
                    buttonText="EUCERIN<sup>®</sup> BABY ECZEMA RELIEF"
                    buttonUrl="/baby/eczema"
                    imgStyles={{ height: 165 }}
                  />
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default EucerinBabyEczemaReliefBodyWashPage;
